<template>
  <div>
    <back-navigation
      title="User Baru"
      :prevent-back="userData.name !== '' || userData.email !== '' || userData.phone !== '' || userData.password !== ''"
      @preventBack="$bvModal.show('preventBack')"
    />
    <b-modal
      id="preventBack"
      title="Ingin keluar?"
      centered
    >
      Yakin ingin keluar? Progress pengisian form anda belum disimpan.
      <template
        #modal-footer
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="secondary"
          @click="$bvModal.hide('preventBack'); $router.go(-1)"
        >
          Ya, keluar
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="$bvModal.hide('preventBack')"
        >
          Tetap Disini
        </b-button>
      </template>
    </b-modal>
    <validation-observer ref="userValidation">
      <b-form @submit.prevent>
        <b-card>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Nama"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nama"
                  rules="required|min:2|max:25"
                >
                  <b-form-input
                    v-model="userData.name"
                    placeholder="Nama user"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Email"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="email|required"
                >
                  <b-form-input
                    v-model="userData.email"
                    placeholder="Email user"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Nomor HP"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nomor HP"
                  rules="required"
                >
                  <b-input-group
                    :class="errors.length > 0 ? 'is-invalid':null"
                    class="input-group-merge"
                    prepend="+62"
                  >
                    <b-form-input
                      v-model="userData.phone"
                      type="number"
                      placeholder="Nomor HP user"
                      :state="errors.length > 0 ? false:null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Password"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required|min:6|max:25"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      v-model="userData.password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <b-row class="mt-50">
                    <b-col>
                      <password
                        v-model="userData.password"
                        :strength-meter-only="true"
                        @score="showScore"
                      />
                    </b-col>
                    <b-col
                      v-if="passwordScore.variant"
                      class="d-flex"
                      cols="auto"
                    >
                      <small :class="`ml-auto ${passwordScore.variant}`">{{ passwordScore.message }}</small>
                    </b-col>
                  </b-row>
                  <small :class="errors.length ? 'text-danger' : 'text-muted'">{{ errors.length ? errors[0] : 'Password minimum 6 karakter, maksimal 25 karakter. Wajib diisi.' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
              offset-md="4"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                block
                class="mb-1 mb-md-0"
                @click="resetForm()"
              >
                Reset
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                @click="loading ? null : submitUser()"
              >
                <b-spinner
                  v-if="loading"
                  variant="light"
                  small
                />
                <span v-else>Tambah User Baru</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BForm,
  BCard,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
  BModal,
} from 'bootstrap-vue'
import BackNavigation from '@/components/misc/BackNavigation.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, min, max,
} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Password from 'vue-password-strength-meter'

export default {
  components: {
    BForm,
    BCard,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    BackNavigation,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    Password,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userData: {
        name: '',
        email: '',
        phone: '',
        password: '',
      },
      required,
      email,
      min,
      max,
      loading: false,
      passwordScore: {},
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    submitUser() {
      this.$refs.userValidation.validate().then(success => {
        if (success) {
          this.loading = true
          this.$store.dispatch('addNewUser', this.userData).then(result => {
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil disimpan!',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$router.replace({ name: 'user-detail', params: { id: result.data.register.id.toString() } })
          }).catch(err => {
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err.graphQLErrors[0].message,
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
    showScore(score) {
      if (score >= 0 && score < 2) {
        this.passwordScore = {
          variant: 'text-danger',
          message: 'Password Lemah',
        }
      } else if (score === 3) {
        this.passwordScore = {
          variant: 'text-info',
          message: 'Password Cukup Bagus',
        }
      } else if (score > 3) {
        this.passwordScore = {
          variant: 'text-success',
          message: 'Password Sangat Bagus',
        }
      }

      if (score === null) {
        this.passwordScore = {
          variant: '',
          message: '',
        }
      }
    },
    resetForm() {
      this.$swal({
        title: 'Reset form?',
        text: 'Progress pengisian form anda akan direset dan tidak dapat dikembalikan.',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.userData = {
            name: '',
            email: '',
            phone: '',
            password: '',
          }
          this.$refs.userValidation.reset()
        }
      })
    },
  },
}
</script>

<style>
.Password {
  max-width: 100% !important;
}
.Password__strength-meter {
  margin-bottom: 8px;
}
</style>
